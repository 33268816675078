<template>
  <div id="block-hour" v-if="selectedPeople && selectedDate && design">
    <div class="box-hour columns is-mobile" v-if="dataReservationEdit.vendorId !== 72">
      <span
        v-if="changeDay"
        :disabled="statusPrevius()"
        @click="previousDate"
        class="btn-arrow column pointer"
      >
        <i class="fa fa-angle-left"></i>
      </span>
      <ul class="dates-list column no-laterals-padding is-four-fifths">
        <li
          class="data-big"
          :class="{
            'disabled-hour':!checkTime(avalibilityHour),
            editHourColor: editPeople
          }"
        >{{ new Date(selectedDate) | moment('timezone', vendorTimezone, 'dddd, DD MMM') }}</li>
      </ul>
      <span
        v-if="changeDay"
        :disabled="blockedNext"
        @click="nextDate"
        class="btn-arrow column has-text-right pointer"
      >
        <i class="fa fa-angle-right"></i>
      </span>
    </div>
    <b-button
      :disabled="preDisabled"
      @click="previus()"
      class="swi-prev btn-arrows"
      :class="{
        editHourColor: editPeople
      }"
      href="javascript:void(0)"
      v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0"
    >
      <i aria-hidden="true" class="fa fa-angle-up" style="font-size:25px"></i>
    </b-button>
    <div v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0">
      <div id="customSwiper">
        <div
          :key="index"
          class="contSwiper"
          :class="slide.length === 1 && slide[0]?.type === 'label' ? 'cont-swiper-label' : 'cont-swiper-aux-grid'"
          v-for="(slide, index) in sortedAndChunkTime"
        >
          <div
            v-for="(data, i) in slide"
            :key="i"
          >
            <!-- Si es un label, renderiza solo el contenido del label -->
            <div v-if="data.type === 'label' && !loadingShiftCustomLabels" class="shift-title">
              <span v-if="!loadingShiftCustomLabels" class="label">
                {{ customLabels[data.label] }}
              </span>
            </div>
            <!-- Si no es un label, renderiza el div normal -->
            <div
              v-else
              :style="{ backgroundColor: currentBackground(data) }"
              :class="{
                'active': data.date == times && data.status && !editPeople,
                'activeEdit': data.date == times && data.status && editPeople,
                'disabled': !data.status || !limitBookingHourDay(),
                editTextHourColor: editPeople
              }"
              @click="setHour(data)"
              class="btnSwiper"
            >
            <span
              class="text"
            >
              {{ data.date | moment('timezone', vendorTimezone, 'hh:mm a') }}
            </span>
            </div>
          </div>
        </div>
        <span id="SwipeActive" v-if="checkTime(slide)"></span>
      </div>
    </div>
    <b-button
      :disabled="preDisabledNext"
      @click="netx()"
      class="swi-next btn-arrows"
      :class="{
        editHourColor: editPeople
      }"
      href="javascript:void(0)"
      v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0"
    >
      <i aria-hidden="true" class="fa fa-angle-down" style="font-size:25px"></i>
    </b-button>
    <div
      class="legendHour"
      :class="{
        editHourColor: editPeople
      }"
      v-if="Array.isArray(avalibilityHour) && avalibilityHour.length > 0"
    >
      <span class="legend-item">
        <div class="legendHour-availability" :class="{editHourAvail: editPeople}"></div>
        <p class="text" style="font-size: 9px">{{ $t('availableHour') }}</p>
      </span>
      <span class="legend-item">
        <div class="legendHour-not-availability"></div>
        <p class="text" style="font-size: 9px">{{ $t('noAvailableHour') }}</p>
      </span>
      <span class="legend-item" v-if="showCurrent">
        <div class="legendHour-current"  :class="{editHourNew: editPeople}" :style="{backgroundColor: oldSelected()}"></div>
        <p class="text" style="font-size: 9px">{{ $t('newEditReservation.hourCurrent') }}</p>
      </span>
      <span class="legend-item" v-if="showCurrent">
        <div class="legendHour-new" :class="{editHourNew: editPeople}"></div>
        <p class="text" style="font-size: 9px">{{ $t('newEditReservation.hourNew') }}</p>
      </span>
    </div>
  </div>
</template>
<script>
// ================ Libraries =============
import _ from 'lodash';
// ================ Types =================
import { mapActions } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import dateNotAvailable from './dateNotAvailable';
import eventTypes from '@/store/types/event';
import { eventBus } from '@/helpers/eventBus';
import serviceVendor from '@/helpers/apis/serviceVendor';

export default {
  props: {
    backgroundC: {},
    color1: {},
    color2: {},
    sectionEdit: {},
    sectionSelect: {},
    changeDay: {
      default: true
    },
    showCurrent: {
      default: false
    },
    editPeople: {
      default: false
    }
  },
  data: () => ({
    date: null,
    now: null,
    slide: 1,
    totalSlide: 0,
    times: null,
    theHour: null,
    msgHourNot: false,
    blockedNext: false,
    arrowTop: false,
    arrowBot: false,
    styleFocus: {
      'background-color': '#000 !important'
    },
    stop: false,
    preDisabled: false,
    preDisabledNext: false,
    idi: 'es',
    shiftData: null,
    shiftInfo: null,
    loadingShiftData: true,
    loadingShiftCustomLabels: true,
    customLabels: []
  }),
  computed: {
    noSchedule () {
      return _.map(this.dates, row =>
        _.map(row, date => (date ? this.getInSchedule(date) : null))
      );
    },
    sortedAndChunkTime () {
      // Mientras los datos están cargándose, devolvemos un array vacío
      if (this.loadingShiftData || !this.shiftData) {
        return [];
      }
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);

      // Si isActiveShiftSelect es 0, mostramos solo avalibilityHour en chunks de 9
      if (this.params.isActiveShiftSelect === 0) {
        let sortedTime = [...this.avalibilityHour].sort(
          (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
        );
        // Filtrar por isWarranty === 0 si editPeople es true
        if (this.editPeople) {
          sortedTime = sortedTime.filter(item => {
            // Verificar si el item no tiene warranty
            const isWarrantyValid = item.isWarranty === 0;
            // Verificar si la configuración existe y que todos los elementos de la configuración tengan isWarranty === 0
            const configValid = item.configuration && item.configuration.length > 0
              ? item.configuration.every(config => config.isWarranty === 0)
              : true;
            // Ambos deben ser válidos
            return isWarrantyValid && configValid;
          });
        }
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
        return this.chunkArray(sortedTime, 9);
      }

      // Extraemos las horas existentes
      const validShiftData = this.shiftData || {};
      const orderedLabels = ['desayuno', 'almuerzo', 'cena']; // Orden deseado
      const validHours = [
        ...(validShiftData.desayuno || []),
        ...(validShiftData.almuerzo || []),
        ...(validShiftData.cena || [])
      ];

      // Filtramos las horas válidas y no válidas de avalibilityHour
      const filteredTime = [];
      const nonMatchedTime = [];

      this.avalibilityHour.forEach((item) => {
        const hourAndMinute = this.$moment(item.dateTime).format('HH:mm');
        if (validHours.includes(hourAndMinute)) {
          filteredTime.push(item);
        } else {
          nonMatchedTime.push(item);
        }
      });

      // Ordenamos los horarios válidos y no válidos
      let sortedTime = [...filteredTime].sort(
        (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
      );
      if (this.editPeople) {
        sortedTime = sortedTime.filter(item => {
          // Verificar si el item no tiene warranty
          const isWarrantyValid = item.isWarranty === 0;
          // Verificar si la configuración existe y que todos los elementos de la configuración tengan isWarranty === 0
          const configValid = item.configuration && item.configuration.length > 0
            ? item.configuration.every(config => config.isWarranty === 0)
            : true;
          // Ambos deben ser válidos
          return isWarrantyValid && configValid;
        });
      }
      let sortedNonMatched = [...nonMatchedTime].sort(
        (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
      );
      if (this.editPeople) {
        sortedNonMatched = sortedNonMatched.filter(item => {
          // Verificar si el item no tiene warranty
          const isWarrantyValid = item.isWarranty === 0;
          // Verificar si la configuración existe y que todos los elementos de la configuración tengan isWarranty === 0
          const configValid = item.configuration && item.configuration.length > 0
            ? item.configuration.every(config => config.isWarranty === 0)
            : true;
          // Ambos deben ser válidos
          return isWarrantyValid && configValid;
        });
      }
      // Creamos el array con los horarios de disponibilidad al inicio
      const timeWithLabels = [];

      if (sortedNonMatched.length > 0) {
        // Agregamos las horas no coincidentes al inicio
        timeWithLabels.push({ type: 'label', label: 'disponibilidad' });
        timeWithLabels.push(...sortedNonMatched);
      }

      // Insertamos los horarios agrupados por sus respectivos labels en el orden correcto
      orderedLabels.forEach((label) => {
        const hours = validShiftData[label] || [];
        const timesForLabel = sortedTime.filter((item) => {
          const itemTime = this.$moment(item.dateTime).format('HH:mm');
          return hours.includes(itemTime);
        });

        if (timesForLabel.length > 0) {
          // Solo agregamos el label si hay horarios correspondientes
          timeWithLabels.push({ type: 'label', label });
          timeWithLabels.push(...timesForLabel);
        }
      });

      // Realizamos el chunk dividiendo por etiquetas o cada 9 si no hay etiquetas
      const chunkedResult = [];
      let currentChunk = [];

      timeWithLabels.forEach((item) => {
        if (item.type === 'label') {
          // Si encontramos un label, empezamos un nuevo chunk
          if (currentChunk.length > 0) {
            chunkedResult.push(currentChunk);
            currentChunk = [];
          }
          // El label se agrega como un chunk independiente
          chunkedResult.push([item]);
        } else {
          // Si no es un label, agregamos al chunk actual
          currentChunk.push(item);
          // Cuando el chunk alcanza 9 elementos, se agrega a los resultados
          if (currentChunk.length === 9) {
            chunkedResult.push(currentChunk);
            currentChunk = [];
          }
        }
      });

      // Agregamos cualquier chunk restante
      if (currentChunk.length > 0) {
        chunkedResult.push(currentChunk);
      }
      this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
      return chunkedResult;
    }
  },
  mounted () {
    this.getShiftHours(this.selectedDate);
    this.getCustomLabelNames();
    this.slide = 1;
    this.times = this.selectedHour;
    this.now = parseInt(this.$moment().format('x'));
    this.theHour = parseInt(this.$moment().milliseconds(0).format('x'));

    if (this.selectedHour) {
      const aux = _.find(this.avalibilityHour, { date: this.selectedHour });
      if (aux) {
        this.setHour(aux);
      }
    }

    this.moveNextHourAvailable();

    const tryScrollToFirstEnabledBtn = () => {
      const swiperContainer = document.getElementById('customSwiper');
      if (!swiperContainer) {
        setTimeout(tryScrollToFirstEnabledBtn, 50);
        return;
      }

      // Escuchar el evento de scroll para actualizar botones
      swiperContainer.addEventListener('scroll', () => {
        this.updateButtonStates(swiperContainer);
      });

      // Actualizar estados de los botones al montar
      this.updateButtonStates(swiperContainer);

      // Buscar el primer botón habilitado y hacer scroll
      const firstEnabledBtn = swiperContainer.querySelector('.btnSwiper:not(.disabled)');
      if (firstEnabledBtn) {
        firstEnabledBtn.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        setTimeout(tryScrollToFirstEnabledBtn, 50);
      }
    };

    tryScrollToFirstEnabledBtn();
  },
  methods: {
    ...mapActions({
      getAvalibilityGroup: whiteLabelTypes.actions.getAvalibilityGroup
    }),
    timeOutLoader (status) {
      setTimeout(() => {
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, status);
      }, 500);
    },
    getCustomLabelNames () {
      this.loadingShiftCustomLabels = true;
      serviceVendor
        .get(`shift/customLabels/${this.vendor.id}`)
        .then(({ data }) => {
          this.customLabels = data.data;
          this.loadingShiftCustomLabels = false;
        })
        .catch(() => {
          this.customLabels = [];
          this.loadingShiftCustomLabels = false;
        });
    },
    getShiftHours (date) {
      const dateSelected = this.$moment(date).isValid() ? this.$moment(date).format('YYYY-MM-DD') : null;

      // Validar si dateSelected es válido antes de realizar la petición
      if (!dateSelected) {
        console.error('La fecha proporcionada no es válida:', date);
        this.shiftData = {};
        return;
      }
      this.loadingShiftData = true;

      serviceVendor
        .get(`turns/${this.vendor.id}/${dateSelected}`)
        .then(({ data }) => {
          this.shiftInfo = data.data || {};
          this.shiftData = data.data || {};
        })
        .catch(() => {
          this.shiftData = {};
        })
        .finally(() => {
          this.loadingShiftData = false;
        });
    },
    currentBackground (data) {
      if (this.isCurrentDate(data) && this.editPeople) {
        let colorObject = this.hexToRgb(this.design.bgColorCheckout);
        if (colorObject === null) {
          colorObject = this.hexToRgb(this.design.color1);
        }
        const colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
        return colorWithOpacity;
      }
      return '';
    },
    isCurrentDate (data) {
      return data.date === parseInt(this.dataReservationEdit.date);
    },
    oldSelected () {
      let colorObject = this.hexToRgb(this.design.bgColorCheckout);
      if (colorObject === null) {
        colorObject = this.hexToRgb(this.design.color1);
      }
      return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, 0.3)`;
    },
    statusPrevius () {
      if (this.selectedDate < parseInt(this.$moment().format('x'))) {
        return true;
      } else {
        return false;
      }
    },
    previus () {
      const swiper = document.getElementById('customSwiper');
      if (!swiper) return;

      this.stop = true;

      const currentScrollTop = Math.round(swiper.scrollTop);
      const height = swiper.offsetHeight;
      const targetScrollTop = Math.max(currentScrollTop - height, 0); // No puede ir más allá del inicio

      // Desplazamiento suave
      const scrollInterval = setInterval(() => {
        if (swiper.scrollTop <= targetScrollTop) {
          clearInterval(scrollInterval);
          this.stop = false;
        } else {
          swiper.scrollTop = Math.max(swiper.scrollTop - 5, targetScrollTop);
        }
      }, 20);

      // Actualizar botones después del scroll
      setTimeout(() => {
        this.updateButtonStates(swiper);
      }, 300);
    },
    netx (page = 1, interval = 20) {
      const swiper = document.getElementById('customSwiper');
      if (!swiper) return;

      this.stop = true;

      const currentScrollTop = Math.round(swiper.scrollTop);
      const height = swiper.offsetHeight;
      const maxScrollTop = swiper.scrollHeight - height; // Límite inferior
      const targetScrollTop = Math.min(currentScrollTop + height * page, maxScrollTop);

      // Desplazamiento suave
      const scrollInterval = setInterval(() => {
        if (swiper.scrollTop >= targetScrollTop) {
          clearInterval(scrollInterval);
          this.stop = false;
        } else {
          swiper.scrollTop = Math.min(swiper.scrollTop + 5, targetScrollTop);
        }
      }, interval);

      // Actualizar botones después del scroll
      setTimeout(() => {
        this.updateButtonStates(swiper);
      }, 300);
    },
    updateButtonStates (swiper) {
      const currentScrollTop = Math.round(swiper.scrollTop);
      const height = swiper.offsetHeight;
      const maxScrollTop = swiper.scrollHeight - height;

      this.preDisabled = currentScrollTop <= 0; // Botón "subir" deshabilitado si está al inicio
      this.preDisabledNext = currentScrollTop >= maxScrollTop; // Botón "bajar" deshabilitado si está al final
    },
    groups () {
      return this.avalibilityHour.length / 4 === 0
        ? 1
        : this.avalibilityHour.length / 4;
    },
    focus (value) {
      this.hasfocus = value;
    },
    limitAvailabilityCalendar (date) {
      let limitDay = this.params.dayCalendarAvailability;
      let datesAvailables = [];
      if (limitDay > 0) {
        for (var i = 0; i < limitDay; i++) {
          datesAvailables.push(
            parseInt(
              this.$moment()
                .add(i, 'days')
                .seconds(0)
                .minutes(0)
                .hours(0)
                .milliseconds(0)
                .format('x')
            )
          );
        }
        if (_.indexOf(datesAvailables, date) >= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    chunkArray (myArray, chunkSize) {
      var index = 0;
      var arrayLength = myArray.length;
      var tempArray = [];
      for (index = 0; index < arrayLength; index += chunkSize) {
        let myChunk = myArray.slice(index, index + chunkSize);
        tempArray.push(myChunk);
      }
      this.totalSlide = tempArray.length;
      return tempArray;
    },
    validationLimit (operator, people) {
      switch (operator) {
        case '>=':
          return (this.selectedPeople >= people);
        case '<=':
          return (this.selectedPeople <= people);
        case '>':
          return (this.selectedPeople > people);
        case '<':
          return (this.selectedPeople < people);
        case '=':
          return (this.selectedPeople === people);
        default:
          return false;
      }
    },
    setHour (data) {
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      let payTypes = ['reservations', 'pombo', 'cena', 'rumba', 'brunch', 'zone'];
      const dayToday = parseInt(this.$moment(parseInt(data.date)).format('d'));
      const costAdult = (data.costAdult >= 0) ? parseInt(data.costAdult) : 0;
      const costBoy = (data.costBoy >= 0) ? parseInt(data.costBoy) : 0;
      const cover = (data.cover >= 0) ? parseInt(data.cover) : 0;
      const purchase = (data.purchase >= 0) ? parseInt(data.purchase) : 0;
      const purchaseDiscount = (data.purchaseDiscount >= 0) ? parseInt(data.purchaseDiscount) : 0;
      const purchaseGeneral = (this.params.purchaseCost > 0) ? parseInt(this.params.purchaseCost) : 0;
      if (data.status && this.limitBookingHourDay()) {
        this.times = data.date;
        // valida si cuando hay un evento y se selecciona una hora del evento
        // esten permitidas reservas normales
        let onlyEvent = false;
        _.forEach(this.allEvents, function (value) {
          if (value.onlyEvent) {
            _.forEach(value.times, function (item) {
              if (parseInt(data.date) === parseInt(item.starDate)) {
                onlyEvent = true;
              }
            });
          }
        });
        let payment = 0;
        // si no es un evento
        if (!onlyEvent) {
          if (data.isWarranty) {
            window.localStorage.setItem('payWarranty', 1);
            eventBus.$emit('showWarrantyText', { value: true });
          } else {
            eventBus.$emit('showWarrantyText', { value: false });
            window.localStorage.removeItem('payWarranty');
            window.localStorage.removeItem('payWarrantyType');
            window.localStorage.removeItem('payWarrantyPrice');
            window.localStorage.removeItem('payWarrantyPriceBoy');
          }

          // validamos las reservas pagas
          if (_.includes(payTypes, data.type)) {
            // primera prioridad cobro por hora global
            if (this.params.minimumChargedPeople) {
              if (this.selectedPeople >= this.params.minimumChargedPeople) {
                if (this.params.purchaseCost > 0) {
                  payment = 1;
                  window.localStorage.setItem('payReservations', purchaseGeneral);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', purchaseGeneral);
                  }
                }
              }
            }

            // segunda prioridad cobro por hora o descuento
            if (purchase > 0 || purchaseDiscount > 0) {
              const today = parseInt(this.$moment().format('d'));
              const dateToday = this.$moment().format('YYYY-MM-DD');
              const dateTodaySelect = this.$moment(parseInt(data.date)).format('YYYY-MM-DD');
              const purchaseDiscount = (data.purchaseDiscount >= 0) ? parseInt(data.purchaseDiscount) : 0;
              const purchase = (data.purchase >= 0) ? parseInt(data.purchase) : 0;
              if ((today !== dayToday || today === dayToday) && dateToday < dateTodaySelect && purchaseDiscount) {
                if (purchaseDiscount > 0) {
                  payment = 1;
                  window.localStorage.removeItem('payReservations');
                  window.localStorage.setItem('payReservations', purchaseDiscount);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', purchaseDiscount);
                  }
                }
              } else {
                if (purchase > 0) {
                  payment = 1;
                  window.localStorage.removeItem('payReservations');
                  window.localStorage.setItem('payReservations', purchase);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', purchase);
                  }
                }
              }
            }

            // tercera prioridad cobro por configuraciones
            if (data.configuration.length > 0) {
              const resultConfig = this.withConfiguration(data);
              if (resultConfig === 1) {
                payment = 1;
              }
            }

            // cuarta prioridad cobro por adultos y niños
            if (this.params.activeSelectorBoy) {
              if (costAdult > 0) {
                payment = 1;
                window.localStorage.removeItem('payReservations');
                window.localStorage.setItem('payReservations', costAdult);
                if (data.isWarranty) {
                  window.localStorage.setItem('payWarrantyType', 'person');
                  window.localStorage.setItem('payWarrantyPrice', costAdult);
                }
              }
              if (costBoy > 0) {
                payment = 1;
                window.localStorage.setItem('payReservationBoys', costBoy);
                if (data.isWarranty) {
                  window.localStorage.setItem('payWarrantyType', 'person');
                  window.localStorage.setItem('payWarrantyPriceBoy', costBoy);
                }
              }
            }

            // quinta prioridad cobro por configuraciones especificas clientes quemadas
            if (this.vendor.id === 573 && (dayToday === 5 || dayToday === 6) && (this.selectedPeople >= 10 && this.selectedPeople <= 15)) {
              payment = 1;
              window.localStorage.removeItem('payReservations');
              window.localStorage.setItem('payReservations', parseInt(20000));
              if (data.isWarranty) {
                window.localStorage.setItem('payWarrantyType', 'person');
                window.localStorage.setItem('payWarrantyPrice', parseInt(20000));
              }
            }
            if (this.vendor.id === 72) {
              if (data.type === 'rumba' || data.type === 'brunch' || data.type === 'cena') {
                if (cover > 0) {
                  payment = 1;
                  localStorage.setItem('payReservations', data.cover);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', data.cover);
                  }
                } else {
                  payment = 0;
                  localStorage.removeItem('payReservations');
                  window.localStorage.removeItem('payReservationBoys');
                  window.localStorage.removeItem('payWarrantyType');
                  window.localStorage.removeItem('payWarrantyPrice');
                }
              }
              if (data.type === 'pombo') {
                const totalAdult = parseInt(this.selectedAdult) * costAdult;
                const totalBoys = parseInt(this.selectedBoy) * costBoy;
                const totalReservation = totalAdult + totalBoys;
                if (totalReservation > 0) {
                  payment = 1;
                  localStorage.setItem('payReservations', totalReservation);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', costAdult);
                    window.localStorage.setItem('payWarrantyPriceBoy', costBoy);
                  }
                } else {
                  payment = 0;
                  localStorage.removeItem('payReservations');
                  window.localStorage.removeItem('payReservationBoys');
                  window.localStorage.removeItem('payWarrantyType');
                  window.localStorage.removeItem('payWarrantyPrice');
                }
              }
            }

            if (payment) {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
            } else {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
            }
          } else {
            // primera prioridad cobro por hora global
            if (this.params.minimumChargedPeople) {
              if (this.selectedPeople >= this.params.minimumChargedPeople) {
                if (this.params.purchaseCost > 0) {
                  payment = 1;
                  window.localStorage.setItem('payReservations', purchaseGeneral);
                  if (data.isWarranty) {
                    window.localStorage.setItem('payWarrantyType', 'person');
                    window.localStorage.setItem('payWarrantyPrice', purchaseGeneral);
                  }
                }
              }
            } else {
              payment = 0;
              window.localStorage.removeItem('payReservations');
              window.localStorage.removeItem('payReservationsFixed');
              window.localStorage.removeItem('payReservationBoys');
              window.localStorage.removeItem('payWarrantyType');
              window.localStorage.removeItem('payWarrantyPrice');
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
            }
            if (payment) {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
            } else {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
            }
          }
          this.$store.commit(reservationTypes.mutations.setSelectedHour, data.date);
        } else {
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          let response = this.allEvents[this.selectedDate];
          response.notAllowReservationNormal = data.date;
          this.$parent.$emit('isEventDay', response);
        }
      } else if (!data.status && this.limitBookingHourDay()) {
        this.$store.commit(reservationTypes.mutations.setSelectedHourAction, data.date);
        this.$emit('hourSelected', data);
        this.$emit('showAction', true);
      }
    },
    withConfiguration (data) {
      let payment = 0;
      data.configuration.sort((a, b) => b.people - a.people);
      for (const i of data.configuration) {
        if (payment === 0) {
          payment = this.paymentConfiguration(i);
          if (payment === 1) break; // Salir del bucle si payment es 1
        }
      }
      return payment;
    },
    paymentConfiguration (data) {
      let payment = 0;
      if (data.isWarranty) {
        window.localStorage.setItem('payWarranty', 1);
        eventBus.$emit('showWarrantyText', { value: true });
      }
      const price = (data.price >= 0) ? parseInt(data.price) : 0;
      if (price > 0 && data.fixed === 1 && data.limit === 0) {
        payment = 1;
        window.localStorage.removeItem('payReservations');
        window.localStorage.setItem('payReservationsFixed', price);
        if (data.isWarranty) {
          window.localStorage.setItem('payWarrantyType', 'fixed');
          window.localStorage.setItem('payWarrantyPrice', price);
        }
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
      } else if (price > 0 && data.fixed === 0 && data.limit === 1 && this.validationLimit(data.operator, data.people)) {
        payment = 1;
        window.localStorage.removeItem('payReservationsFixed');
        window.localStorage.setItem('payReservations', price);
        if (data.isWarranty) {
          window.localStorage.setItem('payWarrantyType', 'person');
          window.localStorage.setItem('payWarrantyPrice', price);
        }
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
      } else if (price > 0 && data.fixed === 1 && data.limit === 1 && this.validationLimit(data.operator, data.people)) {
        payment = 1;
        window.localStorage.removeItem('payReservations');
        window.localStorage.setItem('payReservationsFixed', price);
        if (data.isWarranty) {
          window.localStorage.setItem('payWarrantyType', 'fixed');
          window.localStorage.setItem('payWarrantyPrice', price);
        }
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
      } else if (price > 0 && data.fixed === 0 && data.limit === 0) {
        payment = 1;
        window.localStorage.removeItem('payReservationsFixed');
        window.localStorage.setItem('payReservations', price);
        if (data.isWarranty) {
          window.localStorage.setItem('payWarrantyType', 'person');
          window.localStorage.setItem('payWarrantyPrice', price);
        }
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
      }
      return payment;
    },
    nextDate () {
      this.onChangeDateInHours(this.selectedDate);
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      // const valid = this.$moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD');
      const info = false; // this.blocked.find((i) => i.date === valid);
      if (info) {
        const thes = this;
        const f = this.getNext(parseInt(thes.$moment(thes.selectedDate).add(1, 'days').format('x')));
        thes.$buefy.modal.open({
          parent: thes,
          component: dateNotAvailable,
          hasModalCard: true,
          trapFocus: true,
          props: {
            datePrevius: thes.$moment(thes.selectedDate).add(1, 'days').format('DD MMM YYYY'),
            dateNext: f.format,
            dateIn: parseInt(thes.$moment(thes.selectedDate).add(1, 'days').format('x')),
            dateOut: parseInt(f.clean),
            info
          },
          events: {
            accept (d) {
              const times = parseInt(d);
              thes.$store.commit(reservationTypes.mutations.setSelectedDate, times);
              thes.checkNext();
            },
            nextAccept (d) {
              const times = parseInt(d);
              thes.$store.commit(reservationTypes.mutations.setSelectedDate, times);
              thes.checkNext();
            }
          }
        });
      } else {
        let date = this.$moment(new Date(this.selectedDate)).add(1, 'days');
        let blockedCalendarAvailability = this.limitAvailabilityCalendar(
          parseInt(date.format('x'))
        );
        if (!blockedCalendarAvailability) {
          let time = new Date(date);
          time = time.getTime();
          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
          this.$store.commit(reservationTypes.mutations.setSelectedDate, time);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
          this.$store
            .dispatch({
              type: 'whiteLabel:avalibilityHour',
              data: {
                timestamp: time,
                vendorId: this.$store.getters['whiteLabel:vendorId'],
                people: this.selectedPeople,
                zone: this.selectedZone,
                typeReservation: this.selectedTypeReservation,
                tableId: this.selectedTable
              }
            })
            .then(() => {
              this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
            });
        } else {
          this.blockedNext = true;
        }
      }
    },
    checkNext () {
      let blockedCalendarAvailability = this.limitAvailabilityCalendar(
        parseInt(this.selectedDate)
      );
      if (!blockedCalendarAvailability) {
        this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
        this.$store
          .dispatch({
            type: 'whiteLabel:avalibilityHour',
            data: {
              timestamp: this.selectedDate,
              vendorId: this.$store.getters['whiteLabel:vendorId'],
              people: this.selectedPeople,
              zone: this.selectedZone,
              typeReservation: this.selectedTypeReservation,
              tableId: this.selectedTable
            }
          })
          .then(() => {
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
      } else {
        this.blockedNext = true;
      }
    },
    getNext (date) {
      let info = {
        format: null,
        clean: null
      };
      for (let index = 1; index <= 365; index++) {
        const exists = this.blocked.find((i) => i.date === this.$moment(date).add(index, 'days').format('YYYY-MM-DD'));
        if (exists === undefined) {
          const freeEx = this.schedules.free[this.$moment(date).add(index, 'days').day()];
          const freeExF = this.schedules.extraFree[this.$moment(date).add(index, 'days').format('YYYY-MM-DD')];
          const freeExR = this.schedules.extraReservations[this.$moment(date).add(index, 'days').format('YYYY-MM-DD')];
          if (freeEx !== undefined || freeExF !== undefined || freeExR !== undefined) {
            info.clean = this.$moment(date).add(index, 'days').format('x');
            info.format = this.$moment(date).add(index, 'days').format('DD MMM YYYY');
            break;
          }
        }
      }
      return info;
    },
    previousDate () {
      this.onChangeDateInHours(this.selectedDate);
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      this.blockedNext = false;
      if (this.selectedDate >= parseInt(this.$moment().format('x'))) {
        // const valid = this.$moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD');
        const info = false; // this.blocked.find((i) => i.date === valid);
        if (info) {
          const thes = this;
          const f = thes.getNext(parseInt(thes.$moment(thes.selectedDate).subtract(1, 'days').format('x')));
          thes.$buefy.modal.open({
            parent: thes,
            component: dateNotAvailable,
            hasModalCard: true,
            trapFocus: true,
            props: {
              datePrevius: thes.$moment(thes.selectedDate).subtract(1, 'days').format('DD MMM YYYY'),
              dateNext: f.format,
              dateIn: parseInt(thes.$moment(thes.selectedDate).subtract(1, 'days').format('x')),
              dateOut: parseInt(f.clean),
              info
            },
            events: {
              accept (d) {
                const times = parseInt(d);
                thes.$store.commit(reservationTypes.mutations.setSelectedDate, times);
                thes.checkPrevius();
              },
              nextAccept (d) {
                const times = parseInt(d);
                thes.$store.commit(reservationTypes.mutations.setSelectedDate, times);
                thes.checkPrevius();
              }
            }
          });
        } else {
          let date = this.$moment(this.selectedDate).subtract(1, 'days');
          let blockedCalendarAvailability = this.limitAvailabilityCalendar(
            parseInt(date.format('x'))
          );
          if (!blockedCalendarAvailability) {
            date = this.$moment(date).format('YYYY/MM/DD');
            let time = new Date(date);
            if (this.now <= this.selectedDate) {
              time = time.getTime();
              this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
              this.$store.commit(reservationTypes.mutations.setSelectedDate, time);
              this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
              this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
              this.$store
                .dispatch({
                  type: 'whiteLabel:avalibilityHour',
                  data: {
                    timestamp: time,
                    vendorId: this.$store.getters['whiteLabel:vendorId'],
                    people: this.selectedPeople,
                    zone: this.selectedZone,
                    typeReservation: this.selectedTypeReservation,
                    tableId: this.selectedTable
                  }
                })
                .then(() => {
                  this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
                });
            }
          }
        }
      }
    },
    checkPrevius () {
      let blockedCalendarAvailability = this.limitAvailabilityCalendar(
        parseInt(this.selectedDate)
      );
      if (!blockedCalendarAvailability) {
        if (this.now <= this.selectedDate) {
          this.$store.commit(whiteLabelTypes.mutations.setLoadHour, true);
          this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
          this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
          this.$store
            .dispatch({
              type: 'whiteLabel:avalibilityHour',
              data: {
                timestamp: this.selectedDate,
                vendorId: this.$store.getters['whiteLabel:vendorId'],
                people: this.selectedPeople,
                zone: this.selectedZone,
                typeReservation: this.selectedTypeReservation,
                tableId: this.selectedTable
              }
            })
            .then(() => {
              this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
            });
        }
      }
    },
    limitBookingHourDay () {
      let selected = this.$moment(this.selectedDate).format('YYYY-MM-DD');
      let now = this.$moment().format('YYYY-MM-DD');
      if (selected === now && this.params.limitBookingTimeDay) {
        let limit = now + ' ' + this.params.limitBookingTimeDay;
        limit = this.$moment(limit).format('x');
        let todayNow = this.$moment().format('x');
        if (parseInt(todayNow) <= parseInt(limit)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    checkTime (slide) {
      let aux = 0;
      let thes = this;
      _.forEach(slide, function (item) {
        if ((item.date === thes.times && item.status) || (parseInt(item.date) > thes.theHour && item.status && thes.times == null)) {
          aux = 1;
        }
      });
      if (aux === 1) {
        return true;
      } else {
        return false;
      }
    },
    probeScroll () {
      let data = this.chunkArray(this.avalibilityHour, 9);
      let avail = 0;
      let page = 0;
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          for (let indexTwo = 0; indexTwo < data[index].length; indexTwo++) {
            if (data[index][indexTwo].status) {
              avail++;
            }
          }
          if (avail > 0) {
            page = index;
            break;
          }
        }
        if (page >= 1) {
          this.netx(page, 5);
        }
      }
    },
    onChangeDateInHours (date, next = 1) {
      // Éste método es igual onSelectedDate que está en el componente dateTime
      // se copia tal cual el método para que cuando cambie de días con el next o el previous, haga todo el flujo de selección de fecha y así me muestre los modales que corresponden a esa fecha
      const info = this.blocked.find((i) => i.date === this.$moment(date).format('YYYY-MM-DD'));
      if (info && next === 0) {
        const f = this.getNext(date);
        const thes = this;
        thes.$buefy.modal.open({
          parent: thes,
          component: dateNotAvailable,
          hasModalCard: true,
          trapFocus: true,
          props: {
            datePrevius: thes.$moment(date).format('DD MMM YYYY'),
            dateNext: f.format,
            dateIn: parseInt(date),
            dateOut: parseInt(f.clean),
            info
          },
          events: {
            accept (d) {
              thes.onSelectedDate(d, 1);
            },
            nextAccept (d) {
              thes.onSelectedDate(d);
              const s = parseInt(thes.$moment(d).format('MM'));
              const c = parseInt(thes.$moment(date).format('MM'));
              if (c < 12) {
                if (s > c) {
                  const y = s - c;
                  thes.currentMonth = thes.currentMonth + y;
                }
              } else {
                if (s < c) {
                  thes.currentMonth++;
                }
              }
            }
          }
        });
      } else {
        this.dateStyle = date;
        const sameDate = (this.selectedDat === date) && true;
        this.$store.commit(eventTypes.mutations.setEventPrice, 0);
        this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
        this.$store.commit(whiteLabelTypes.mutations.setAvalibilityHour, []);
        if (!this.params.isActiveSelectedZone) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          this.$emit('closeAlerts', true);
          if (
            this.vendor.id === 72 &&
            this.selectedTypeReservation === 'Rumba' &&
            (dayOfWeek === 5 || dayOfWeek === 6) &&
            this.params.isMapped === 1 && !this.allEvents[date]
          ) {
            if (this.zone !== false && this.editPeople === true) {
              this.$store.commit(reservationTypes.mutations.setSelectedTable, this.dataReservationEdit.tableId);
              this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
              this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
              this.$emit('change-date', true);
              let response = this.setSelectedDates(date, this.now, this.allEvents);
              this.$emit('experiences', this.findExperience());
              this.$parent.$emit('isEventDay', response.evento);
              this.selectedDat = date;
            } else {
              this.$emit('show-modal-select-zone-map', date);
            }
          } else {
            if (!this.params.listReservationType && this.vendor.id !== 170) {
              this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
            }
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
            this.$emit('change-date', true);
            let response = this.setSelectedDates(date, this.now, this.allEvents);
            this.$emit('experiences', this.findExperience());
            this.$parent.$emit('isEventDay', response.evento);
            this.selectedDat = date;
          }
        } else {
          if (this.selectedZone !== false && this.editPeople === true && this.dataReservationEdit.balancePaid > 0) {
            this.$emit('closeAlerts', true);
            this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
            this.$store.commit(reservationTypes.mutations.setSelectedZone, this.zone);
            this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
            this.$emit('change-date', true);
            let response = this.setSelectedDates(date, this.now, this.allEvents);
            this.$emit('experiences', this.findExperience());
            this.$parent.$emit('isEventDay', response.evento);
          } else {
            this.$emit('show-modal-select-zone', date);
          }
        }
        if (sameDate) {
          this.changeHours();
        }
      }
    },
    anticipationTime (time) {
      if (this.getTimeAtentionWl != null) {
        const cena = (this.schedules || {}).cena || {};
        const pombo = (this.schedules || {}).pombo || {};
        const rumba = (this.schedules || {}).rumba || {};
        const brunch = (this.schedules || {}).brunch || {};
        let at = (this.params.anticipationTime >= 0) ? parseInt(this.params.anticipationTime) : 0;
        let dataHour = [];
        if (this.todayDate === time) {
          let actualHora = this.actualHora + at;
          let serverTime = this.serverTime;
          let dayOfWeek = this.$moment(actualHora).format('d');
          let extraFreeFlag = false;
          if (Object.keys(this.getTimeAtentionWl['extraFree']).length > 0) {
            if (this.getTimeAtentionWl['extraFree'][time] !== undefined) {
              let ultimo = this.getTimeAtentionWl['extraFree'][time].slice(
                -1
              )[0];
              let hora = ultimo.hora.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (
            Object.keys(this.getTimeAtentionWl['extraReservations']).length >
            0 &&
            !extraFreeFlag
          ) {
            if (
              this.getTimeAtentionWl['extraReservations'][time] !== undefined
            ) {
              let ultimo = this.getTimeAtentionWl['extraReservations'][time].slice(-1)[0];
              let hora = ultimo.hora.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Cena') {
            let aux = [];
            cena.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Pombo') {
            let aux = [];
            pombo.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Rumba') {
            let aux = [];
            rumba.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (this.selectedTypeReservation === 'Brunch') {
            let aux = [];
            brunch.map(function (data) {
              if (data.dayOfWeek === dayOfWeek) {
                aux.push(data.close);
              }
            });
            aux = _.sortedUniq(aux);
            if (aux.length > 0) {
              let ultimo = this.$moment(aux[0]).format('HH:mm');
              let hora = ultimo.split(':');
              let horaCompleta = this.$moment(serverTime).hours(hora[0]).minutes(hora[1]).seconds(0).milliseconds(0).format('x');
              dataHour.push(horaCompleta);
              extraFreeFlag = true;
            }
          }
          if (dataHour.length > 0 && extraFreeFlag) {
            dataHour = _.sortedUniq(dataHour);
            let ultimoHabil = dataHour.slice(-1)[0];
            let diferencia = ultimoHabil - actualHora;
            if (diferencia >= 0) {
              return false;
            } else {
              return true;
            }
          } else if (this.getTimeAtentionWl[dayOfWeek].length > 0) {
            let ultimoRegistro = this.getTimeAtentionWl[dayOfWeek][this.getTimeAtentionWl[dayOfWeek].length - 1];
            let horaUltimoRegistro = ultimoRegistro.hora.split(':');
            let horaCompletaUltimoRegistro = this.$moment(serverTime)
              .hours(horaUltimoRegistro[0])
              .minutes(horaUltimoRegistro[1])
              .seconds(0)
              .milliseconds(0)
              .format('x');
            let diferencia = horaCompletaUltimoRegistro - actualHora;
            if (diferencia >= 0) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    usingMoment (current) {
      if (parseInt(this.$moment(current).format('x')) < parseInt(this.$moment(this.now).format('x'))) {
        return true;
      } else {
        return false;
      }
    },
    rumbaDay (date) {
      if (this.editPeople === true) {
        let selectedDayEdit = parseInt(
          this.$moment(this.dataReservationEdit.fechaCompleta)
            .seconds(0)
            .minutes(0)
            .hours(0)
            .milliseconds(0)
            .format('d')
        );
        if (this.dataReservationEdit.typeReservation === 'Rumba' && this.dataReservationEdit.vendorId === 72 && (selectedDayEdit === 5 || selectedDayEdit === 6)) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          let dayOfWeekSelected = parseInt(
            this.$moment(this.selectedDate)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          if (dayOfWeekSelected === 5 || dayOfWeekSelected === 6) {
            if (dayOfWeek !== 5 && dayOfWeek !== 6) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else if (this.dataReservationEdit.typeReservation === 'Rumba' && this.dataReservationEdit.vendorId === 72 && (selectedDayEdit !== 5 || selectedDayEdit !== 6)) {
          let dayOfWeek = parseInt(
            this.$moment(date)
              .seconds(0)
              .minutes(0)
              .hours(0)
              .milliseconds(0)
              .format('d')
          );
          if (selectedDayEdit === dayOfWeek) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    findExperience () {
      let experience = _.map(this.allEvents, item => {
        if (item.type === 'experience' && item.alwaysActive) return item;
      });
      if (experience[0] !== undefined) {
        return experience;
      } else {
        return false;
      }
    },
    moveNextHourAvailable () {
      if (document.getElementById('customSwiper')) {
        setTimeout(() => {
          document.getElementById('customSwiper').scrollTop = 0;
        }, 100);
        document.getElementById('customSwiper').addEventListener('wheel', event => {
          event.preventDefault();
          const delta = Math.sign(event.deltaY);
          if (!this.stop) {
            if (delta < 0) {
              this.previus();
            } else {
              this.netx();
            }
          }
        });
        var initialX = null;
        var initialY = null;
        document.getElementById('customSwiper').addEventListener('touchstart', e => {
          initialX = e.touches[0].clientX;
          initialY = e.touches[0].clientY;
        }, false);
        document.getElementById('customSwiper').addEventListener('touchmove', e => {
          e.preventDefault();
          if (initialX === null) { return; }
          if (initialY === null) { return; }
          var currentX = e.touches[0].clientX;
          var currentY = e.touches[0].clientY;
          var diffX = initialX - currentX;
          var diffY = initialY - currentY;
          e.preventDefault();
          if (!this.stop) {
            if (Math.abs(diffX) > Math.abs(diffY)) {
              // if (diffX > 0) { } else { }
            } else {
              if (diffY > 0) {
                this.netx();
              } else {
                this.previus();
              }
            }
          }
          e.preventDefault();
          initialX = null;
          initialY = null;
          e.cancelable && e.preventDefault();
        }, false);
        if (this.sectionSelect === false) {
          if (document.getElementById('SwipeActive')) {
            setTimeout(() => {
              document.getElementById('SwipeActive').scrollIntoView();
            }, 100);
          }
        }
        let thes = this;
        let arrowDisabled = document.getElementById('customSwiper').scrollTop;
        var heightCustomSwiper = document.getElementById('customSwiper').scrollHeight - 120;
        if (arrowDisabled >= 0 && arrowDisabled <= 10) {
          thes.preDisabled = true;
        } else {
          thes.preDisabled = false;
        }
        if ((arrowDisabled >= (heightCustomSwiper - 10)) && (arrowDisabled <= (heightCustomSwiper + 10))) {
          thes.preDisabledNext = true;
        } else {
          thes.preDisabledNext = false;
        }
        this.probeScroll();
      }
    }
  },
  watch: {
    selectedHour () {
      this.times = this.selectedHour;
    },
    avalibilityHour () {
      if (Array.isArray(this.avalibilityHour)) {
        let data = this.chunkArray(this.avalibilityHour, 3);
        let thes = this;
        if (document.getElementById('SwipeActive')) {
          setTimeout(() => {
            document.getElementById('SwipeActive').scrollIntoView();
          }, 100);
        }
        _.forEach(data, function (item) {
          let aux = _.find(item, { date: thes.selectedHour });
          if (aux !== undefined) {
            if (!aux.status) {
              thes.$store.commit(reservationTypes.mutations.setSelectedHour, null);
            }
          }
        });
      }
    },
    sectionEdit () {
      if (document.getElementById('SwipeActive')) {
        setTimeout(() => {
          document.getElementById('SwipeActive').scrollIntoView();
        }, 100);
      }
    },
    sectionSelect () {
      if (document.getElementById('SwipeActive')) {
        setTimeout(() => {
          document.getElementById('SwipeActive').scrollIntoView();
        }, 100);
      }
    }
  }
};
</script>
<style lang="scss">
#block-hour {
  width: 100%;
}
.swi-prev {
  color: var(--color1);
}
.swi-next {
  color: var(--color1);
}
.box-hour {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 20px;
  .dates-list {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    li {
      padding: 0 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.h-general {
  color: var(--color1);
  border: 1px solid var(--color1);
}
.h-active {
  color: var(--color2) !important;
  background-color: var(--color1) !important;
  font-weight: 900 !important;
  border: 1px solid var(--color1) !important;
}
.editTextHourColor {
  color: var(--bgColorCheckout) !important;
  border-color: var(--bgColorCheckout) !important;
}
.editHourColor {
  color: var(--bgColorCheckout) !important;
}
.editHourAvail {
  border-color: var(--bgColorCheckout);
  background-color: var(--colorCheckout);
}
.editHourNew {
  border-color: var(--colorCheckout);
  background-color: var(--bgColorCheckout);
}
.data-big {
  color: var(--color1);
  font-size: 16px;
  cursor: default;
  user-select: none;
  &.disabled-hour {
    color: #aaaaaa52;
    font-weight: bold;
  }
}
.data-small {
  color: var(--color1) !important;
  font-size: 11px;
  opacity: 0.5;
}
.btn-arrow {
  color: var(--color1);
  background-color: transparent;
  font-size: 22px;
}
.btn-arrows {
  color: var(--color1);
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  padding: 5px;
  border: none;
  &.button {
    background-color: inherit !important;
    border-color: inherit !important;
    border: inherit;
    &:disabled {
      opacity: 0.1;
    }
    &:focus {
      outline: none !important;
      border: none !important;
      color: var(--color1);
    }
    &:focus:not(:active) {
      box-shadow: inherit;
    }
    &:hover {
      color: hsl(0, 0%, 21%)
    }
  }
}
.btn-hour {
  padding: 6px 40px !important;
  pointer-events: visible !important;
  cursor: pointer;
  font-size: 13px;
  background: transparent;
  border-radius: 0.3rem !important;
  font-weight: 600;
  &:hover {
    color: var(--color1) !important;
    border: 1px solid var(--color1) !important;
    background-color: transparent !important;
  }
}
#preSwiper {
  height: 110px !important;
  max-height: 110px !important;
  min-height: 110px !important;
  overflow: hidden;
}
.swiper-slide {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 110px !important;
  max-height: 110px !important;
  min-height: 110px !important;
  position: relative;
  #SwipeActive {
    position: absolute;
    background: #ff00ff;
    height: 0px;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.info {
  background-color: transparent !important;
  border-color: var(--cardTitleColor);
  color: var(--cardTitleColor);
}
.notification-title {
  text-align: center !important;
}
.vue-notification {
  border-left: unset;
}

.disabled,
.disabled:hover {
  background-color: rgba(181, 179, 179, 0.34) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  opacity: unset !important;
}
.swiper-container {
  padding-bottom: unset;
}
#customSwiper {
  height: 120px;
  min-height: 132px;
  max-height: 132px;
  min-width: 300px;
  overflow: auto;
  scrollbar-width: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .contSwiper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // height: 120px;
    // min-height: 120px;
    gap: 16px;
    row-gap: 14px;
    align-content: center;
    &.cont-swiper-aux-grid{
      display: flex;
      justify-items: center;
      align-content: flex-start;
      padding: 7px 0;
    }
    &.cont-swiper-label {
      min-height: 18px;
      height: 18px;
      margin-bottom: 5px;
      margin-top: 4px;
    }
  }
  .btnSwiper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 30px;
    color: var(--color1);
    border: 1px solid var(--color1);
    border-radius: 4px;
    cursor: pointer;
    &--doubleHour{
      width: 138px;
    }
    &--doubleHour{
      width: 138px;
    }
    .text {
      cursor: pointer;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.separatorSwipper{
        padding: 0px 1ch;
      }
    }
    &:hover {
      color: var(--color2);
      border: 1px solid var(--color2);
      background-color: var(--color1);
    }
    &.active {
      color: var(--color2);
      border: 1px solid var(--color2);
      background-color: var(--color1);
    }
    &.activeEdit {
      color: var(--colorCheckout) !important;
      background-color: var(--bgColorCheckout) !important;
    }
    &.disabled {
      background-color: rgba(181, 179, 179, 0.34);
      color: rgba(0, 0, 0, 0.26);
      border: 1px solid rgba(0, 0, 0, 0.26);
      opacity: unset;
    }
  }
  .shift-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    background-color: transparent;
    .label {
      color: var(--color1);
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
#customSwiper::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.legendHour {
  &.left {
    justify-content: left;
    padding-left: 10%;
  }
}
</style>
